import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';

function App() {
  return (
    <div className="page">
      <div className="demo" style={{fontFamily: 'Machine56DB01', fontWeight: 'normal', fontStyle: 'normal'}}>
          <h1>sefflebynight.se</h1>
      <img src="./img/korean.png" style={{float:'right'}} />
      <div className="line-top"></div>
          <div className="console">
        <p>
          /SITE
        </p>
        <p>
          <span className="bold">INACTIVE</span>
        </p>
        <hr />
        <p>
          <span id="large"></span>
        </p>
        <p>	
          <span className="bold"><span id="small"></span></span>
        </p>	
      </div>
      <div className="box-corner"></div>
      <div className="scanBox">
        <div className="console ver2">
          <div className="row" style={{textAlign:'center'}}>
            <div className="col-md-3">
              <img src="./img/QR.png" />
            </div>
            <div className="col-md-6">
              <p className="scan-text">/SCAN</p>
            </div>
            <div className="col-md-3">
            </div>
          </div>
        </div>
        <div className="box-corner"></div>
      </div>
      <hr />
      <img src="./img/barCode.png" style={{float:'right'}} />
      <div className="line-bottom-div">
        <div className="line-bottom"><div id="time"></div></div>
        <div className="cornered"></div>
      </div>
      </div>
    </div>
  );
}

export default App;
